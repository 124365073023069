<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        xl="10"
        class="mb-2"
      >
        <h1>
          {{ $t('meeting.exercise.title') }}
        </h1>

        <div
          v-if="processing"
          class="my-5 text-center"
        >
          <b-spinner />
        </div>

      </b-col>
    </b-row>

    <b-row v-if="showNoExerciseResultsAlert">
      <b-col
        cols="12"
        xl="10"
      >
        <b-alert show>
          <h4 class="alert-heading">
            {{ $t('meeting.exercise.alert.no-exercises-title') }}
          </h4>
          <div class="alert-body p-1">
            {{ $t('meeting.exercise.alert.no-exercises-body') }}
          </div>
        </b-alert>
      </b-col>
    </b-row>

    <b-row
      v-for="calendarEventCustomer in calendarEventCustomers"
      v-show="calendarEventCustomer.present"
      :key="`calendar-event-customer-${calendarEventCustomer.id}`"
    >
      <b-col
        v-if="calendarEventCustomer.exerciseResults.length"
        cols="12"
        xl="10"
        class="mb-1"
      >
        <h2>{{ calendarEventCustomer.customer.lastName }} {{ calendarEventCustomer.customer.firstName }}</h2>
      </b-col>

      <b-col
        v-for="exerciseResult in calendarEventCustomer.exerciseResults"
        :key="`exercise-result-${exerciseResult.id}`"
        cols="12"
        xl="10"
      >
        <b-card :title="exerciseResult.exercise.title">

          <div
            v-if="exerciseResult.exercise.description"
            class="mb-2"
          >
            <h6 class="section-label">
              {{ $t('meeting.exercise.description') }}
            </h6>

            <div v-html="exerciseResult.exercise.description" />
          </div>

          <div
            v-if="exerciseResult.solution"
            class="mb-2"
          >
            <h6 class="section-label">
              {{ $t('meeting.exercise.solution') }}
            </h6>

            <div v-html="exerciseResult.solution" />
          </div>

          <div
            v-if="exerciseResult.attachments.length"
            class="mb-2"
          >

            <div
              v-for="(attachment) in exerciseResult.attachments"
              :key="'attachment-' + attachment.id"
            >
              <div class="mb-1">

                <b-link @click="download(attachment)">
                  <feather-icon icon="PaperclipIcon" />
                  {{ attachment.title }}
                </b-link>

              </div>
            </div>

          </div>

          <div>
            <h6 class="section-label">
              {{ $t('meeting.exercise.rating.title') }}
            </h6>
          </div>

          <b-form-rating
            v-model="exerciseResult.rating"
            variant="warning"
            inline
            size="xl"
            class="mb-1"
            @change="changeHandler(exerciseResult)"
          />

          <text-editor
            id="meeting-note-text-content"
            v-model="exerciseResult.comment"
            variant="simple"
            :label="$t('meeting.exercise.rating.comment').toString()"
            @change="changeHandler(exerciseResult)"
          />

        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BAlert,
  BCard, BCol, BFormRating, BLink, BRow, BSpinner,
} from 'bootstrap-vue'
import TextEditor from '@/views/_global/TextEditor'
import exercisesSetup from '@/views/organization/meeting/tabs/exercises/exercisesSetup'

export default {
  components: {
    BFormRating,
    BRow,
    BCol,
    BCard,
    BSpinner,
    BLink,
    BAlert,

    TextEditor,
  },
  setup: exercisesSetup,
}
</script>
