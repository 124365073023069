import { computed, ref } from '@vue/composition-api'
import store from '@/store'

export default () => {
  const processing = ref(false)
  const meeting = computed(() => store.getters['calendar/getCalendarEvent'])

  return {
    processing,
    meeting,
  }
}
