<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="5"
        xl="4"
      >
        <b-row>
          <b-col>

            <b-card>
              <rc-overlay
                :show="processing"
                no-fade
                variant="white"
                class="h-100"
              >
                <div
                  v-if="meetingEndsAt === false"
                  class="text-center"
                >
                  {{ $t('meeting.general.ended') }}:
                </div>
                <div
                  v-else-if="meetingEndsAt"
                  class="text-center"
                >
                  <timer
                    :show="true"
                    :time="meetingEndsAt"
                  />
                </div>
                <b-button
                  v-else-if="!processing"
                  class="btn-block"
                  variant="primary"
                  @click="startMeeting"
                >
                  {{ $t('meeting.general.start-meeting') }}
                </b-button>
              </rc-overlay>
            </b-card>

            <meeting-details
              :meeting="meeting"
              :navigation="viewNavigation"
              :processing="processing"
            />

          </b-col>
        </b-row>
      </b-col>

      <b-col
        cols="12"
        md="7"
        xl="6"
      >

        <b-row>

          <b-col v-if="!processing && !scripts.length">
            <b-alert show>
              <h4 class="alert-heading">
                {{ $t('meeting.general.alert.no-script-available.title') }}
              </h4>
              <div class="alert-body p-1">
                {{ $t('meeting.general.alert.no-script-available.body') }}
              </div>
            </b-alert>
          </b-col>

          <!-- Script select -->
          <b-col
            v-if="(scripts.length && !script.id) || forceChangeScript"
            cols="12"
          >

            <b-card :title="$t('meeting.general.script')">
              <rc-overlay
                :show="processing"
                no-fade
                variant="white"
                class="h-100"
              >
                <b-form-group
                  label-for="script"
                >
                  <v-select
                    v-model="meeting.script"
                    :options="scripts"
                    label="subject"
                    :reduce="script => script.id"
                    :placeholder="$t('meeting.general.select-script')"
                    input-id="scripts"
                    @input="setScript"
                  >
                    <template v-slot:option="option">
                      <span :class="option.icon" />
                      <div>{{ option.subject }}</div>
                      <small
                        v-if="option.group"
                        class="text-muted"
                      >{{ option.group }}</small>
                    </template>

                    <template #no-options>
                      {{ $t('shared.no-data') }}
                    </template>

                  </v-select>

                  <div
                    class="d-flex justify-content-between"
                  >
                    <b-link
                      v-if="viewNavigation && viewNavigation.suggestedScriptId"
                      class="mt-1"
                      @click="setScript(viewNavigation.suggestedScriptId)"
                    >
                      <small>
                        {{ $t('meeting.general.use-suggested') }}
                      </small>
                    </b-link>
                    <b-link
                      v-if="forceChangeScript"
                      class="mt-1"
                      @click="forceChangeScript = false"
                    >
                      <small>
                        {{ $t('shared.btn.cancel').toLowerCase() }}
                      </small>
                    </b-link>
                  </div>
                </b-form-group>

                <!-- No scripts alert -->
                <b-alert
                  :show="!scripts.length"
                  variant="info"
                >
                  <h4 class="alert-heading">
                    <feather-icon
                      class="mr-25"
                      icon="InfoIcon"
                    />
                    {{ $t('shared.pro-tip') }}
                  </h4>
                  <div class="alert-body">
                    {{ $t('meeting.general.no-script-alert') }}
                  </div>
                </b-alert>
              </rc-overlay>
            </b-card>
          </b-col>

          <!-- Script details -->
          <b-col
            v-if="script.subject && !forceChangeScript"
            cols="12"
          >
            <b-card
              :title="script.subject"
              :sub-title="script.scriptGroup.name"
            >
              <rc-overlay
                :show="processing"
                no-fade
                variant="white"
                class="h-100"
              >
                <div v-if="script.id && !forceChangeScript">
                  <div v-if="script.scriptGroup.coursebook || script.coursebookNotes">
                    <b-row>
                      <b-col v-if="script.scriptGroup.coursebook">
                        <h5>{{ $t('script.group.label.coursebook') }}</h5>
                        <p>
                          {{ script.scriptGroup.coursebook }}
                        </p>
                      </b-col>
                      <b-col v-if="script.coursebookNotes">
                        <h5>{{ $t('script.scripts.label.coursebook-notes') }}</h5>
                        <p>
                          {{ script.coursebookNotes }}
                        </p>
                      </b-col>
                    </b-row>
                  </div>

                </div>
              </rc-overlay>

              <template #footer>
                <div class="text-right">
                  <b-button
                    variant="flat-primary"
                    @click="forceChangeScript = true"
                  >
                    {{ $t('shared.btn.change') }}
                  </b-button>
                </div>
              </template>
            </b-card>
          </b-col>

          <!-- Script content -->
          <b-col
            v-if="script.content && !forceChangeScript"
            cols="12"
          >
            <b-card :title="$t('meeting.general.lesson-purpose')">
              <rc-overlay
                :show="processing"
                no-fade
                variant="white"
                class="h-100"
              >
                <div v-html="script.content" />
              </rc-overlay>
            </b-card>
          </b-col>

        </b-row>

      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BAlert, BButton,
  BCard, BCol, BFormGroup, BLink, BRow, VBModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import RcOverlay from '@/layouts/components/rc/RcOverlay'
import Timer from '@/layouts/components/rc/Timer'
import MeetingDetails from '@/views/organization/meeting/partials/MeetingDetails'
import generalSetup from '@/views/organization/meeting/tabs/general/generalSetup'

export default {
  components: {
    MeetingDetails,
    BFormGroup,
    BCard,
    BRow,
    BCol,
    BAlert,
    BButton,
    BLink,

    RcOverlay,
    vSelect,
    Timer,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    globalProcessing: {
      type: Boolean,
      default: false,
    },
  },
  setup: generalSetup,
}
</script>
