<template>
  <div>
    <div class="d-flex align-items-center justify-content-between">
      <b-form-checkbox
        v-model="localValue"
        :indeterminate="isIndeterminate"
        :disabled="disabled"
        @input="newValue => $emit('input', newValue)"
        @change="newValue => $emit('change', newValue)"
      >
        {{ item.title }}
      </b-form-checkbox>
      <b-button
        v-if="item.description"
        variant="flat-primary"
        size="sm"
        @click="showDescription = !showDescription"
      >
        <feather-icon
          v-if="showDescription"
          icon="ChevronDownIcon"
        />
        <feather-icon
          v-else
          icon="ChevronUpIcon"
        />
      </b-button>
    </div>
    <div
      v-if="item.description && showDescription"
      class="mt-1"
      v-html="item.description"
    />
  </div>
</template>
<script>
import { BButton, BFormCheckbox } from 'bootstrap-vue'

export default {
  components: { BButton, BFormCheckbox },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    isChecked: {
      type: Boolean,
      default: null,
    },
    item: {
      type: Object,
      required: true,
    },
    isIndeterminate: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showDescription: false,
      localValue: this.isChecked === null ? this.value : this.isChecked,
    }
  },
  watch: {
    isChecked() {
      this.localValue = this.isChecked === null ? this.value : this.isChecked
    },
    value() {
      this.localValue = this.isChecked === null ? this.value : this.isChecked
    },
  },
}
</script>
