import formValidation from '@core/comp-functions/forms/form-validation'
import { ref, computed } from '@vue/composition-api'
import store from '@/store'
import { cloneNested, parseRequestError } from '@/helpers/helpers'
import useAttachment from '@/views/_global/useAttachment'

export default (props, { emit }) => {
  const meeting = computed(() => store.getters['calendar/getCalendarEvent'])
  const meetingId = computed(() => meeting.value.id)
  const script = computed(() => store.getters['script/getScript'])

  const {
    editingAttachment,
    attachmentList,

    addType,
    editType,

    groupAttachments,
    editAttachment,
    addAttachment,
    handleNewAttachment,
    handleEditAttachment,
    deleteAttachment,
    download,
  } = useAttachment(emit)

  const {
    refFormObserver: refScriptFormObserver,
    getValidationState,
    resetForm,
  } = formValidation()

  const loading = ref(false)

  const links = computed(() => {
    const localScriptAttachments = groupAttachments(attachmentList.value).ScriptAttachmentUrl ?? []
    const localCalendarEventAttachments = groupAttachments(attachmentList.value).CalendarEventAttachmentUrl ?? []
    return localScriptAttachments.concat(localCalendarEventAttachments)
  })
  const files = computed(() => {
    const localScriptAttachments = groupAttachments(attachmentList.value).ScriptAttachmentFile ?? []
    const localCalendarEventAttachments = groupAttachments(attachmentList.value).CalendarEventAttachmentFile ?? []
    return localScriptAttachments.concat(localCalendarEventAttachments)
  })
  const texts = computed(() => {
    const localScriptAttachments = groupAttachments(attachmentList.value).ScriptAttachmentText ?? []
    const localCalendarEventAttachments = groupAttachments(attachmentList.value).CalendarEventAttachmentText ?? []
    return localScriptAttachments.concat(localCalendarEventAttachments)
  })

  const fetchAttachments = () => {
    loading.value = true
    const promises = [store.dispatch('attachment/fetchAttachmentsByResource', { resource: 'calendar-event', resourceId: meeting.value.id })]
    if (script.value.id) {
      promises.push(store.dispatch('attachment/fetchAttachmentsByResource', {
        resource: 'script',
        resourceId: script.value.id,
      }))
    }
    Promise.all(promises)
      .then(response => {
        if (response.length === 2) {
          attachmentList.value = response[0].data.concat(response[1].data)
        } else {
          attachmentList.value = response[0].data
        }
      })
      .catch(err => parseRequestError(err))
      .finally(() => {
        loading.value = false
      })
  }

  fetchAttachments()

  const canModify = attachment => ['CalendarEventAttachmentUrl', 'CalendarEventAttachmentFile', 'CalendarEventAttachmentText'].includes(attachment.type)

  return {
    meetingId,

    loading,
    editingAttachment,
    attachmentList,
    links,
    files,
    texts,
    addType,
    editType,

    refScriptFormObserver,
    getValidationState,
    resetForm,
    editAttachment,
    addAttachment,
    download,
    fetchAttachments,
    canModify,

    deleteAttachment,
    handleNewAttachment,
    handleEditAttachment,
    cloneNested,
  }
}
