import { computed, ref } from '@vue/composition-api'
import store from '@/store'
import i18n from '@/libs/i18n'
import { cloneNested, getColorVariantByPaymentStatus, parseRequestError } from '@/helpers/helpers'
import SettlementStatusDictionary from '@/dictionary/SettlementStatusDictionary'

export default (props, { emit }) => {
  const processing = ref(false)
  const localValue = cloneNested(props.value)

  const meeting = computed(() => store.getters['calendar/getCalendarEvent'])

  const processPromise = (promise, toastText) => {
    processing.value = true
    return promise
      .then(() => {
        window.toast.notify.success(toastText)
      })
      .catch(err => parseRequestError(err))
      .finally(() => {
        processing.value = false
      })
  }

  const togglePresence = attendee => {
    localValue.present = localValue.present ? 0 : 1
    processPromise(store.dispatch(
      'calendar/setCalendarEventCustomerPresence',
      { calendarEventCustomerId: attendee.id, presence: (localValue.present) },
    ),
    i18n.t('meeting.attendees.alert.presence-updated'))

    emit('input', localValue)
  }

  const handleNewSettlementAdded = settlementData => {
    if (settlementData.status === SettlementStatusDictionary.Paid) {
      localValue.settlement.paid = true
      window.toast.notify.success(i18n.t('meeting.attendees.alert.payment-added'))
      emit('input', localValue)
    }
  }

  return {
    processing,
    meeting,

    togglePresence,
    handleNewSettlementAdded,
    getColorVariantByPaymentStatus,
  }
}
