<template>
  <b-card
    no-body
    class="card-developer-meetup"
  >
    <b-card-body>
      <rc-overlay
        :show="processing"
        variant="white"
        class="h-100"
      >
        <div v-if="meeting.service">
          <!-- meeting header -->
          <div class="meetup-header d-flex align-items-center">
            <div class="meetup-day">
              <h6 class="mb-0">
                {{ $t(`shared.days.short.${day.day()}`).toUpperCase() }}
              </h6>
              <h3 class="mb-0">
                {{ day.format('D') }}
              </h3>
            </div>
            <div class="my-auto">
              <b-card-title
                class="mb-25"
              >
                {{ meeting.service.name }}
              </b-card-title>
            </div>
          </div>
          <!--/ meeting header -->

          <!-- meeting navigation -->
          <div
            v-if="navigation"
            class="d-flex mb-1"
          >
            <b-button
              v-if="navigation.prevEventId"
              variant="flat-primary"
              class="flex-fill"
              :to="{name: 'organization-meeting-view', params: {id: navigation.prevEventId}}"
            >
              <feather-icon
                icon="ChevronsLeftIcon"
              />
            </b-button>
            <b-button
              v-if="navigation.nextEventId"
              variant="flat-primary"
              class="flex-fill"
              :to="{name: 'organization-meeting-view', params: {id: navigation.nextEventId}}"
            >
              <feather-icon
                icon="ChevronsRightIcon"
              />
            </b-button>
          </div>
          <!--/ meeting navigation -->

          <!-- media -->
          <b-media
            v-for="media in mediaData"
            :key="media.avatar"
            no-body
          >
            <b-media-aside
              v-if="media.title"
              class="mr-1"
            >
              <b-avatar
                rounded
                variant="light-primary"
                size="34"
              >
                <feather-icon
                  :icon="media.avatar"
                  size="18"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="overflow-hidden">
              <h6 class="mb-0">
                {{ media.title }}
              </h6>
              <b-link
                v-if="isUrl(media.subtitle)"
                v-b-tooltip
                :title="media.subtitle"
                class="w-100 text-nowrap text-ellipsis"
                :href="media.subtitle"
                target="_blank"
              >
                {{ getUrlHostname(media.subtitle) }}
                <feather-icon
                  icon="ExternalLinkIcon"
                  size="12"
                />
              </b-link>
              <small v-else>{{ media.subtitle }}</small>
            </b-media-body>
          </b-media>
        </div>
      </rc-overlay>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardTitle, BMedia, BMediaAside, BAvatar, VBTooltip, BMediaBody, BButton,
} from 'bootstrap-vue'
import dayjs from 'dayjs'
import { capitalizeFirstLetter, fixAndReturnUrl, isUrl } from '@/helpers/helpers'
import RcOverlay from '@/layouts/components/rc/RcOverlay'
/* eslint-disable global-require */
export default {
  components: {
    BCard,
    BCardBody,
    BCardTitle,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BButton,
    RcOverlay,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    meeting: {
      type: Object,
      required: true,
    },
    processing: {
      type: Boolean,
      default: false,
    },
    navigation: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    day() {
      return dayjs(this.meeting.startAt)
    },
    dayTo() {
      return dayjs(this.meeting.endAt)
    },
    dayString() {
      return capitalizeFirstLetter(this.$t(`shared.days.long.${this.day.day()}`))
    },
    month() {
      return this.$t(`shared.months-for-full-date.${this.day.month()}`)
    },
    mediaData() {
      const mediaData = [
        {
          avatar: 'CalendarIcon',
          title: `${this.dayString}, ${this.day.date()} ${this.month} ${this.day.year()}`,
          subtitle: this.$t('shared.from-to', { from: this.day.format('HH:mm'), to: this.dayTo.format('HH:mm') }),
        },
        {
          avatar: 'MapPinIcon',
          title: this.meeting.location ? this.$t(`shared.service-location.${this.meeting.location}`) : null,
          subtitle: this.meeting.locationDetails,
        },
      ]

      if (this.meeting.calendarEventSequence.id) {
        mediaData.push(
          {
            avatar: 'RefreshCwIcon',
            title: this.meeting.calendarEventSequence ? this.$t('meeting.general.recurring') : null,
            subtitle: this.meeting.calendarEventSequence ? this.$t('meeting.general.meeting-number-of', { number: this.meeting.ordinalNumber, of: this.meeting.calendarEventSequence.calendarEventsCount }) : null,
          },
        )
      }

      return mediaData
    },
  },
  methods: {
    isUrl(str) {
      return isUrl(str)
    },
    getUrlHostname(url) {
      return fixAndReturnUrl(url).hostname
    },
  },
}
</script>
