<template>
  <b-tabs
    :key="`meeting-view-${$route.params.id}`"
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab
      active
      lazy
    >

      <!-- title -->
      <template #title>
        <feather-icon
          icon="FileTextIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">
          {{ $t('meeting.general.title') }}
        </span>
      </template>

      <general :global-processing="processing" />
    </b-tab>
    <!--/ general tab -->

    <!-- attendees tab -->
    <b-tab
      lazy
    >

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UsersIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">
          {{ $t('meeting.attendees.title') }}
        </span>
      </template>

      <attendees :global-processing="processing" />
    </b-tab>
    <!--/ attendees tab -->

    <!-- materials tab -->
    <b-tab
      lazy
    >

      <!-- title -->
      <template #title>
        <feather-icon
          icon="PaperclipIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">
          {{ $t('meeting.materials.title') }}
        </span>
      </template>

      <materials :global-processing="processing" />
    </b-tab>
    <!--/ materials tab -->

    <!-- knowledge check tab -->
    <b-tab
      lazy
    >

      <!-- title -->
      <template #title>
        <feather-icon
          icon="CheckIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">
          {{ $t('meeting.knowledge-check.title') }}
        </span>
      </template>

      <knowledge-check :global-processing="processing" />
    </b-tab>
    <!--/ knowledge check tab -->

    <!-- exercises tab -->
    <b-tab
      lazy
    >

      <!-- title -->
      <template #title>
        <feather-icon
          icon="HomeIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">
          {{ $t('meeting.exercise.title') }}
        </span>
      </template>

      <exercises :global-processing="processing" />
    </b-tab>
    <!--/ exercises tab -->

    <!-- meeting summary tab -->
    <b-tab
      lazy
    >

      <!-- title -->
      <template #title>
        <feather-icon
          icon="ListIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">
          {{ $t('meeting.meeting-summary.title') }}
        </span>
      </template>

      <meeting-summary :global-processing="processing" />
    </b-tab>
    <!--/ meeting summary tab -->

  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import {
  ref, computed,
} from '@vue/composition-api'
import General from '@/views/organization/meeting/tabs/general/General'
import Attendees from '@/views/organization/meeting/tabs/attendees/Attendees'
import Materials from '@/views/organization/meeting/tabs/materials/Materials'
import KnowledgeCheck from '@/views/organization/meeting/tabs/knowledge-check/KnowledgeCheck'
import Exercises from '@/views/organization/meeting/tabs/exercises/Exercises'
import MeetingSummary from '@/views/organization/meeting/tabs/meeting-summary/MeetingSummary'
import store from '@/store'

export default {
  components: {
    BTabs,
    BTab,
    General,
    Attendees,
    Materials,
    KnowledgeCheck,
    Exercises,
    MeetingSummary,
  },

  watch: {
    $route(to, from) {
      if (to.params.id === from.params.id) {
        return
      }

      this.init(to.params.id)
    },
  },
  setup: (_, { root }) => {
    const route = root.$route

    const meetingId = computed(() => parseInt(route.params.id, 10))
    const processing = ref(false)

    const init = id => {
      processing.value = true
      Promise.all([
        store.dispatch('calendar/fetchCalendarEvent', id),
        store.dispatch('script/fetchScripts'),
      ])
        .then(res => {
          if (res[0].script) {
            store.dispatch('script/fetchScript', res[0].script.id)
              .then(() => {
                processing.value = false
              })
          } else {
            store.dispatch('script/resetScript')
            processing.value = false
          }
        })
    }

    init(meetingId.value)

    return {
      processing,
      meetingId,
      init,
    }
  },
}
</script>
