<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        xl="10"
        class="mb-2"
      >
        <h1>
          <b-button
            v-if="!summaryConfirmed && false"
            class="float-right"
            variant="flat-primary"
            @click="confirmSummary"
          >
            <!-- confirmation button is hidden and probably should be deleted -->
            {{ $t('meeting.meeting-summary.confirm.btn') }}
          </b-button>
          {{ $t('meeting.meeting-summary.title') }}
        </h1>
        <h6
          v-if="summaryConfirmed"
          class="text-muted text-uppercase"
        >
          {{ $t('meeting.meeting-summary.confirm.confirmed-at') }}:
          {{ transformDatetimeToHumanReadablePretty(meeting.summaryConfirmedAt) }}
        </h6>
      </b-col>

      <b-col
        cols="12"
        xl="10"
        class="mb-1"
      >
        <b-alert :show="!processing && !script.id">
          <h4 class="alert-heading">
            {{ $t('meeting.meeting-summary.alert.no-script-selected.title') }}
          </h4>
          <div class="alert-body p-1">
            {{ $t('meeting.meeting-summary.alert.no-script-selected.body') }}
          </div>
        </b-alert>

        <b-card
          v-if="selected && script.knowledgeChecks && script.knowledgeChecks.length"
          :title="$t('script.knowledge-checklist.title')"
        >
          <div
            v-for="knowledgeCheck in script.knowledgeChecks"
            :key="`knowledge-check-${knowledgeCheck.id}`"
          >
            <summary-check-item
              :item="knowledgeCheck"
              :is-checked="allChecked(selected.knowledgeChecks, knowledgeCheck.id)"
              :is-indeterminate="!allEqual(selected.knowledgeChecks, knowledgeCheck.id)"
              :disabled="summaryConfirmed"
              @change="value => {listChangeHandler(knowledgeCheck.id, 'knowledgeChecks', value)}"
            />
          </div>
        </b-card>

        <b-card
          v-if="selected && script.exercises && script.exercises.length"
          :title="$t('script.exercise.title')"
        >
          <div
            v-for="exercise in script.exercises"
            :key="`exercise-${exercise.id}`"
          >
            <summary-check-item
              :item="exercise"
              :is-checked="allChecked(selected.exercises, exercise.id)"
              :is-indeterminate="!allEqual(selected.exercises, exercise.id)"
              :disabled="summaryConfirmed"
              @change="value => {listChangeHandler(exercise.id, 'exercises', value)}"
            />
          </div>
        </b-card>

        <b-card
          v-if="(summaryConfirmed && meeting.meetingNote) || !summaryConfirmed"
          :title="$t('meeting.meeting-summary.summary')"
        >
          <rc-overlay
            :show="processing"
            no-fade
            variant="white"
            class="h-100"
          >
            <div
              v-if="summaryConfirmed"
              v-html="meeting.meetingNote"
            />
            <text-editor
              v-else
              id="meeting-note-text-content"
              v-model="meeting.meetingNote"
              variant="simple"
              @change="changeHandler"
            />

            <div v-if="presentAttendees.length === 1">
              <h6 class="section-label">
                {{ $t('meeting.meeting-summary.customer-notes') }}
              </h6>
              <editable-textarea
                v-model="presentAttendees[0].customer.notes"
                value-path="notes"
                :debounce="2500"
                :maxlength="10000"
                :inline="false"
                @input="updateCustomer(presentAttendees[0].customer)"
              />
            </div>
          </rc-overlay>
        </b-card>
      </b-col>
    </b-row>

    <b-row v-show="presentAttendees.length > 1">
      <b-col
        cols="12"
        xl="10"
      >
        <h2>{{ $t('meeting.meeting-summary.individual-summary') }}</h2>
      </b-col>

      <b-col
        cols="12"
        xl="10"
      >
        <!-- collapse -->
        <app-collapse
          accordion
          type="margin"
        >
          <app-collapse-item
            v-for="(attendee) in meeting.calendarEventCustomerObjects"
            v-show="attendee.present"
            :key="`attendee-${attendee.id}`"
            :title="`${attendee.customer.lastName} ${attendee.customer.firstName}`"
          >
            <b-row>
              <b-col
                v-if="selected && script.knowledgeChecks && script.knowledgeChecks.length"
                cols="12"
                class="mb-1"
              >
                <h6 class="section-label">
                  {{ $t('script.knowledge-checklist.title') }}
                </h6>

                <div v-if="selected.knowledgeChecks[attendee.id.toString()]">
                  <summary-check-item
                    v-for="knowledgeCheck in script.knowledgeChecks"
                    :key="`knowledge-check-${knowledgeCheck.id}-attendee-${attendee.id}`"
                    v-model="selected.knowledgeChecks[attendee.id.toString()][knowledgeCheck.id.toString()]"
                    :item="knowledgeCheck"
                    :disabled="summaryConfirmed"
                    @input="value => attendeeChangeHandler(attendee.id, 'knowledgeChecks', knowledgeCheck.id, value)"
                  />
                </div>
              </b-col>

              <b-col
                v-if="selected && script.exercises && script.exercises.length"
                cols="12"
                class="mb-1"
              >
                <h6 class="section-label">
                  {{ $t('script.exercise.title') }}
                </h6>

                <div v-if="selected.exercises[attendee.id.toString()]">
                  <summary-check-item
                    v-for="exercise in script.exercises"
                    :key="`exercise-${exercise.id}-attendee-${attendee.id}`"
                    v-model="selected.exercises[attendee.id.toString()][exercise.id.toString()]"
                    :item="exercise"
                    :disabled="summaryConfirmed"
                    @input="value => attendeeChangeHandler(attendee.id, 'exercises', exercise.id, value)"
                  />
                </div>
              </b-col>

              <b-col
                v-if="(summaryConfirmed && attendee.meetingNote) || !summaryConfirmed"
                cols="12"
              >
                <h6 class="section-label">
                  {{ $t('meeting.meeting-summary.summary') }}
                </h6>

                <div
                  v-if="summaryConfirmed"
                  v-html="attendee.meetingNote"
                />
                <text-editor
                  v-else
                  :id="`attendee-meeting-note-${attendee.id}`"
                  v-model="attendee.meetingNote"
                  variant="simple"
                  @change="attendeeNoteChangeHandler(attendee.id, attendee.meetingNote)"
                />
              </b-col>

              <b-col
                class="mb-1"
                cols="12"
              >
                <h6 class="section-label">
                  {{ $t('meeting.meeting-summary.customer-notes') }}
                </h6>
                <editable-textarea
                  v-model="attendee.customer.notes"
                  value-path="notes"
                  :debounce="2500"
                  :maxlength="10000"
                  :inline="false"
                  @input="updateCustomer(attendee.customer)"
                />
              </b-col>

            </b-row>

          </app-collapse-item>

        </app-collapse>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {
  BAlert,
  BButton,
  BCard, BCol, BRow,
} from 'bootstrap-vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem'
import AppCollapse from '@core/components/app-collapse/AppCollapse'
import meetingSummarySetup from '@/views/organization/meeting/tabs/meeting-summary/meetingSummarySetup'
import SummaryCheckItem from '@/views/organization/meeting/tabs/meeting-summary/SummaryCheckItem'
import TextEditor from '@/views/_global/TextEditor'
import RcOverlay from '@/layouts/components/rc/RcOverlay'
import EditableTextarea from '@/views/organization/customers/tabs/EditableTextarea.vue'

export default {
  components: {
    EditableTextarea,
    SummaryCheckItem,
    TextEditor,
    RcOverlay,

    AppCollapseItem,
    AppCollapse,

    BRow,
    BCol,
    BCard,
    BButton,
    BAlert,
  },
  setup: meetingSummarySetup,
}
</script>
