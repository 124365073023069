<template>
  <div>
    <add-attachment-modal
      v-if="meetingId"
      resource="calendar-event"
      :resource-id="meetingId"
      :type.sync="addType"
      :attachment-list="attachmentList"
    />

    <edit-attachment-modal
      v-if="meetingId"
      v-model="editingAttachment"
      resource="calendar-event"
      :resource-id="meetingId"
      :type.sync="editType"
    />

    <attachment-event-handler
      @fetch-attachments="fetchAttachments"
      @start-processing="loading = true"
      @stop-processing="loading = false"
    />

    <h2 class="mb-2">
      {{ $t('meeting.materials.title') }}
    </h2>

    <b-row v-if="!loading && !attachmentList.length">
      <b-col
        cols="12"
        xl="10"
      >
        <b-alert show>
          <h4 class="alert-heading">
            {{ $t('meeting.materials.alert.no-materials-title') }}
          </h4>
          <div class="alert-body p-1">
            {{ $t('meeting.materials.alert.no-materials-body') }}
          </div>
        </b-alert>
      </b-col>
    </b-row>

    <b-row>

      <!-- Texts -->
      <b-col
        v-if="texts.length"
        cols="12"
        xl="10"
        class="mb-3"
      >
        <b-row class="d-flex justify-content-between mb-1 mx-0">
          <h3>
            {{ $t('attachment.text.title-plural') }}
          </h3>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-modal.add-attachment-modal
            size="sm"
            variant="flat-primary"
            @click="addAttachment(attachmentList, 'text')"
          >
            {{ $t('shared.btn.add') }}
            <feather-icon icon="PlusIcon" />
          </b-button>
        </b-row>

        <!-- collapse -->
        <app-collapse
          accordion
          type="margin"
        >

          <app-collapse-item
            v-for="attachment in texts"
            :key="attachment.id"
            :title="attachment.title"
          >

            <div v-html="attachment.content" />

            <div v-if="canModify(attachment)">
              <b-link
                v-b-modal.edit-attachment-modal
                class="float-right my-1 ml-1"
                @click="editAttachment(attachment, attachmentList, 'text')"
              >
                <feather-icon
                  icon="EditIcon"
                />
              </b-link>

              <b-link
                class="text-danger float-right my-1 ml-1"
                @click="deleteAttachment(attachment, attachmentList)"
              >
                <feather-icon
                  icon="TrashIcon"
                />
              </b-link>
            </div>

          </app-collapse-item>

        </app-collapse>

      </b-col>

      <!-- Files -->
      <b-col
        v-if="files.length"
        cols="12"
        xl="10"
        class="mb-1"
      >

        <b-row class="d-flex justify-content-between mb-1 mx-0">
          <h3>
            {{ $t('attachment.file.title-plural') }}
          </h3>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-modal.add-attachment-modal
            size="sm"
            variant="flat-primary"
            @click="addAttachment(attachmentList, 'file')"
          >
            {{ $t('shared.btn.add') }}
            <feather-icon icon="PlusIcon" />
          </b-button>
        </b-row>

        <b-row class="match-height">
          <b-col
            v-for="attachment in files"
            :key="attachment.id"
            sm="12"
            xl="6"
          >
            <b-card>
              <div
                v-if="canModify(attachment)"
                class="ml-2 float-right"
              >
                <b-link
                  class="text-danger my-1 ml-1"
                  @click="deleteAttachment(attachment, attachmentList)"
                >
                  <feather-icon
                    icon="TrashIcon"
                  />
                </b-link>

                <b-link
                  v-b-modal.edit-attachment-modal
                  class=" my-1 ml-1"
                  @click="editAttachment(attachment, attachmentList, 'file')"
                >
                  <feather-icon icon="EditIcon" />
                </b-link>
              </div>

              <b-link @click="download(attachment)">
                <feather-icon icon="FileIcon" />
                {{ attachment.title }}
              </b-link>
            </b-card>
          </b-col>
        </b-row>

      </b-col>

      <!-- Links -->
      <b-col
        v-if="links.length"
        cols="12"
        xl="10"
        class="mb-1"
      >
        <b-row class="d-flex justify-content-between mb-1 mx-0">
          <h3>
            {{ $t('attachment.link.title-plural') }}
          </h3>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-modal.add-attachment-modal
            size="sm"
            variant="flat-primary"
            @click="addAttachment(attachmentList, 'url')"
          >
            {{ $t('shared.btn.add') }}
            <feather-icon icon="PlusIcon" />
          </b-button>
        </b-row>

        <b-row
          class="match-height"
        >
          <b-col
            v-for="attachment in links"
            :key="attachment.id"
            sm="12"
            md="6"
          >
            <url-attachment-preview
              :attachment="attachment"
              :enable-delete="canModify(attachment)"
              :enable-edit="canModify(attachment)"
              @delete-attachment="deleteAttachment(attachment, attachmentList)"
              @edit-attachment="editAttachment(attachment, attachmentList, 'url')"
            />
          </b-col>
        </b-row>

      </b-col>

      <b-col
        cols="12"
        xl="10"
      >

        <b-card
          :title="$t('shared.attachments')"
        >
          <rc-overlay
            :show="loading"
            no-fade
            variant="white"
            class="h-100"
          >

            <b-button
              v-b-modal.add-attachment-modal
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              class="btn-block"
            >
              <feather-icon icon="PlusIcon" />
              {{ $t('script.btn.add-attachment') }}
            </b-button>

          </rc-overlay>
        </b-card>

      </b-col>

    </b-row>

  </div>
</template>

<script>
import {
  BButton, BCard, BRow, BCol, VBModal, BLink, BAlert,
} from 'bootstrap-vue'
import { required, alphaNum, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import RcOverlay from '@/layouts/components/rc/RcOverlay'
import AddAttachmentModal from '@/views/_global/AddAttachmentModal'
import UrlAttachmentPreview from '@/views/_global/UrlAttachmentPreview'
import EditAttachmentModal from '@/views/_global/EditAttachmentModal'
import AttachmentEventHandler from '@/views/_global/AttachmentEventHandler'
import materialsSetup from '@/views/organization/meeting/tabs/materials/materialsSetup'

export default {
  components: {
    AttachmentEventHandler,
    UrlAttachmentPreview,
    BRow,
    BCol,
    BButton,
    BCard,
    BLink,
    BAlert,

    AppCollapseItem,
    AppCollapse,

    RcOverlay,
    AddAttachmentModal,
    EditAttachmentModal,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    globalProcessing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  setup: materialsSetup,
}
</script>
