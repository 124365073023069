import { computed, ref } from '@vue/composition-api'
import dayjs from 'dayjs'
import store from '@/store'
import i18n from '@/libs/i18n'
import { parseRequestError } from '@/helpers/helpers'

export default (props, { root }) => {
  const route = root.$route
  const meetingId = parseInt(route.params.id, 10)

  const localProcessing = ref(false)
  const processing = computed(() => props.globalProcessing || localProcessing.value)
  const forceChangeScript = ref(false)
  const viewNavigation = ref()

  const meeting = computed(() => store.getters['calendar/getCalendarEvent'])
  const meetingEndsAt = computed(() => {
    if (meeting.value.startedAt) {
      const endsAt = dayjs(meeting.value.startedAt).add(meeting.value.service.duration, 'minute')
      if (endsAt.diff() < 0) {
        return false
      }

      return endsAt.toJSON()
    }
    return null
  })
  const script = computed(() => store.getters['script/getScript'])
  const scripts = computed(() => {
    const localScripts = []
    store.getters['script/getScripts'].forEach(localScript => {
      localScripts.push({
        id: localScript.id,
        subject: localScript.subject,
        group: localScript.scriptGroup?.name,
      })
    })

    return localScripts
  })

  const processPathCalendarEventPromise = (promise, enableProcessing = false, enableToast = false) => {
    localProcessing.value = enableProcessing
    return promise
      .then(() => {
        if (enableToast) {
          window.toast.notify.success(i18n.t('meeting.general.toast.updated'))
        }
      })
      .catch(err => parseRequestError(err))
      .finally(() => {
        localProcessing.value = false
      })
  }

  const setScript = scriptId => {
    processPathCalendarEventPromise(
      store.dispatch(
        'calendar/patchCalendarEvent',
        {
          calendarEventId: meetingId,
          data: { script: scriptId },
        },
      ),
      true, true,
    )

    if (scriptId) {
      store.dispatch('script/fetchScript', scriptId)
        .then(() => {
          forceChangeScript.value = false
        })
    } else {
      store.dispatch('script/resetScript')
      forceChangeScript.value = false
    }
  }

  const startMeeting = () => {
    const time = dayjs().toISOString()
    meeting.value.startedAt = time
    processPathCalendarEventPromise(
      store.dispatch(
        'calendar/patchCalendarEvent',
        {
          calendarEventId: meetingId,
          data: { startedAt: time },
        },
      ),
    )
      .catch(err => {
        parseRequestError(err)
      })
  }

  store.dispatch('calendar/getCalendarEventViewNavigation', meetingId)
    .then(res => {
      viewNavigation.value = res.data
    })

  return {
    processing,
    forceChangeScript,

    meeting,
    viewNavigation,
    meetingEndsAt,
    script,
    scripts,
    setScript,
    startMeeting,
  }
}
