<template>
  <div>
    <h2 class="mb-1">
      {{ $t('meeting.attendees.title') }}
    </h2>

    <b-row v-if="!meeting.calendarEventCustomers.length">
      <b-col
        cols="12"
        lg="10"
      >
        <b-alert
          show
          variant="warning"
        >
          <h4 class="alert-heading">
            {{ $t('meeting.attendees.alert.no-attendees-title') }}
          </h4>
          <div class="alert-body p-1">
            {{ $t('meeting.attendees.alert.no-attendees-body') }}
          </div>
        </b-alert>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col
        v-for="(attendee, index) in meeting.calendarEventCustomerObjects"
        :key="attendee.id"
        cols="12"
        lg="5"
      >
        <attendees-card v-model="meeting.calendarEventCustomerObjects[index]" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BAlert, BCol, BRow } from 'bootstrap-vue'
import attendeesSetup from '@/views/organization/meeting/tabs/attendees/attendeesSetup'
import AttendeesCard from '@/views/organization/meeting/tabs/attendees/AttendeesCard'

export default {
  components: {
    AttendeesCard,
    BRow,
    BCol,
    BAlert,
  },
  props: {
    globalProcessing: {
      type: Boolean,
      default: false,
    },
  },
  setup: attendeesSetup,
}
</script>
