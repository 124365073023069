import {
  computed, ref,
} from '@vue/composition-api'
import _ from 'lodash'
import store from '@/store'
import { parseRequestError } from '@/helpers/helpers'

export default () => {
  const meeting = computed(() => store.getters['calendar/getCalendarEvent'])
  const meetingId = meeting.value.id
  const processing = ref(true)
  const showQuestions = ref(false)
  const calendarEventCustomers = ref([])
  const showNoKnowledgeCheckAlert = computed(() => {
    if (processing.value) return false
    if (calendarEventCustomers.value.length === 0) return true
    return !calendarEventCustomers.value.some(calendarEventCustomer => calendarEventCustomer.knowledgeCheckRatings.length > 0)
  })

  const timeouts = {}

  const sort = data => _.orderBy(
    data,
    [
      item => item.customer.lastName,
      item => item.customer.firstName,
    ],
    'asc',
  )

  const fetchData = () => {
    processing.value = true
    store.dispatch('calendar/fetchCalendarEventPreviousKnowledgeChecks', meetingId)
      .then(res => {
        calendarEventCustomers.value = sort(res.data)
      })
      .catch(err => parseRequestError(err))
      .finally(() => {
        processing.value = false
      })
  }

  fetchData()

  const saveChanges = knowledgeCheckRating => {
    store.dispatch(
      'calendar/patchKnowledgeCheckRating',
      {
        id: knowledgeCheckRating.id,
        data: {
          rating: knowledgeCheckRating.rating,
          comment: knowledgeCheckRating.comment,
        },
      },
    )
      .catch(err => parseRequestError(err))
  }

  const changeHandler = knowledgeCheckRating => {
    clearTimeout(timeouts[knowledgeCheckRating.id])
    timeouts[knowledgeCheckRating.id] = setTimeout(() => {
      saveChanges(knowledgeCheckRating)
    }, 2500)
  }

  return {
    processing,
    showNoKnowledgeCheckAlert,
    showQuestions,

    calendarEventCustomers,
    changeHandler,
  }
}
