<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        xl="10"
        class="mb-2"
      >
        <h1>
          <b-button
            v-if="!processing && calendarEventCustomers.length"
            class="float-right"
            variant="flat-primary"
            @click="showQuestions = !showQuestions"
          >
            {{ showQuestions ? $t('meeting.knowledge-check.hide-questions') : $t('meeting.knowledge-check.show-questions') }}
          </b-button>

          {{ $t('meeting.knowledge-check.title') }}
        </h1>

        <div
          v-if="processing"
          class="my-5 text-center"
        >
          <b-spinner />
        </div>

      </b-col>
    </b-row>

    <b-row v-if="showNoKnowledgeCheckAlert">
      <b-col
        cols="12"
        xl="10"
      >
        <b-alert show>
          <h4 class="alert-heading">
            {{ $t('meeting.knowledge-check.alert.no-knowledge-checks-title') }}
          </h4>
          <div class="alert-body p-1">
            {{ $t('meeting.knowledge-check.alert.no-knowledge-checks-body') }}
          </div>
        </b-alert>
      </b-col>
    </b-row>

    <b-row
      v-for="calendarEventCustomer in calendarEventCustomers"
      :key="`calendar-event-customer-${calendarEventCustomer.id}`"
    >
      <b-col
        v-if="calendarEventCustomer.knowledgeCheckRatings.length"
        cols="12"
        xl="10"
        class="mb-1"
      >
        <h2>{{ calendarEventCustomer.customer.lastName }} {{ calendarEventCustomer.customer.firstName }}</h2>
      </b-col>

      <b-col
        v-for="knowledgeCheckRating in calendarEventCustomer.knowledgeCheckRatings"
        :key="`knowledge-check-rating-${knowledgeCheckRating.id}`"
        cols="12"
        xl="10"
      >
        <b-card :title="knowledgeCheckRating.knowledgeCheck.title">

          <div
            v-if="knowledgeCheckRating.knowledgeCheck.description"
            class="mb-2"
          >
            <h6 class="section-label">
              {{ $t('meeting.knowledge-check.description') }}
            </h6>

            <div v-html="knowledgeCheckRating.knowledgeCheck.description" />
          </div>

          <div
            v-if="knowledgeCheckRating.knowledgeCheck.exampleQuestions && showQuestions"
            class="mb-2"
          >
            <h6 class="section-label">
              {{ $t('meeting.knowledge-check.example-questions') }}
            </h6>

            <div v-html="knowledgeCheckRating.knowledgeCheck.exampleQuestions" />
          </div>

          <div>
            <h6 class="section-label">
              {{ $t('meeting.knowledge-check.rating.title') }}
            </h6>
          </div>

          <b-form-rating
            v-model="knowledgeCheckRating.rating"
            variant="warning"
            inline
            size="xl"
            class="mb-1"
            @change="changeHandler(knowledgeCheckRating)"
          />

          <text-editor
            id="meeting-note-text-content"
            v-model="knowledgeCheckRating.comment"
            variant="simple"
            :label="$t('meeting.knowledge-check.rating.comment').toString()"
            @change="changeHandler(knowledgeCheckRating)"
          />

        </b-card>
      </b-col>
    </b-row>
  </div></template>

<script>
import {
  BAlert,
  BButton,
  BCard, BCol, BFormRating, BRow, BSpinner,
} from 'bootstrap-vue'
import knowledgeCheckSetup from '@/views/organization/meeting/tabs/knowledge-check/knowledgeCheckSetup'
import TextEditor from '@/views/_global/TextEditor'

export default {
  components: {
    BFormRating,
    BRow,
    BCol,
    BCard,
    BButton,
    BSpinner,
    BAlert,

    TextEditor,
  },
  setup: knowledgeCheckSetup,
}
</script>
