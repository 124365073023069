import {
  computed, ref,
} from '@vue/composition-api'
import Swal from 'sweetalert2'
import store from '@/store'
import { cloneNested, parseRequestError } from '@/helpers/helpers'
import { transformDatetimeToHumanReadablePretty } from '@/helpers/dateTimeHelper'
import i18n from '@/libs/i18n'

export default props => {
  const localProcessing = ref(false)
  const confirmingSummary = ref(false)
  const processing = computed(() => props.globalProcessing || localProcessing.value)

  let timeout = null

  const meeting = computed(() => store.getters['calendar/getCalendarEvent'])
  const meetingId = computed(() => meeting.value.id)
  const presentAttendees = computed(() => meeting.value.calendarEventCustomerObjects.filter(attendee => attendee.present))
  const script = computed(() => store.getters['script/getScript'])
  const summaryConfirmed = computed(() => Boolean(meeting.value.summaryConfirmedAt || confirmingSummary.value))

  const presentStudentsCount = computed(() => meeting.value.calendarEventCustomers.filter(s => Boolean(s.present)).length)
  const selected = ref(null)

  localProcessing.value = true
  store.dispatch('calendar/fetchCalendarEventSummary', meetingId.value)
    .then(res => {
      selected.value = res.data
    })
    .catch(err => parseRequestError(err))
    .finally(() => {
      localProcessing.value = false
    })

  if (meeting.value.script?.id && meeting.value.script?.id !== script.value.id) {
    localProcessing.value = true
    store.dispatch('script/fetchScript', meeting.value.script.id)
      .catch(err => parseRequestError(err))
      .finally(() => {
        localProcessing.value = false
      })
  }

  const processPathCalendarEventPromise = (promise, enableProcessing = false, enableToast = false) => {
    localProcessing.value = enableProcessing
    return promise
      .then(() => {
        if (enableToast) {
          window.toast.notify.success(i18n.t('meeting.general.toast.updated'))
        }
      })
      .catch(err => parseRequestError(err))
      .finally(() => {
        localProcessing.value = false
      })
  }

  const saveNote = () => {
    processPathCalendarEventPromise(
      store.dispatch(
        'calendar/patchCalendarEvent',
        {
          calendarEventId: meetingId.value,
          data: { meetingNote: meeting.value.meetingNote },
        },
      ),
    )
  }

  const changeHandler = () => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      saveNote()
    }, 2500)
  }

  const listChangeHandler = (itemId, source, value) => {
    // console.log(itemId, source, value)
    Object.keys(selected.value[source]).forEach(calendarEventCustomerId => {
      selected.value[source][calendarEventCustomerId][itemId] = value
    })

    selected.value = cloneNested(selected.value)
  }

  // const attendeeChangeHandler = () => {
  const attendeeChangeHandler = (calendarEventCustomerId, source, itemId, value) => {
    // console.log(calendarEventCustomerId, source, itemId, value)

    if (source === 'knowledgeChecks' && value === true) {
      store.dispatch('calendar/addKnowledgeCheckRating', { calendarEventCustomerId, knowledgeCheckId: itemId })
    }
    if (source === 'knowledgeChecks' && value === false) {
      store.dispatch('calendar/removeKnowledgeCheckRating', { calendarEventCustomerId, knowledgeCheckId: itemId })
    }
    if (source === 'exercises' && value === true) {
      store.dispatch('calendar/addExerciseResult', { calendarEventCustomerId, exerciseId: itemId })
    }
    if (source === 'exercises' && value === false) {
      store.dispatch('calendar/removeExerciseResult', { calendarEventCustomerId, exerciseId: itemId })
    }

    selected.value = cloneNested(selected.value)
  }

  const confirmSummary = () => {
    Swal.fire({
      title: i18n.t('meeting.meeting-summary.alert.confirm.title'),
      text: i18n.t('meeting.meeting-summary.alert.confirm.subtitle'),
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: i18n.t('meeting.meeting-summary.alert.confirm.btn-yes'),
      cancelButtonText: i18n.t('meeting.meeting-summary.alert.confirm.btn-no'),
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ml-1',
      },
      buttonsStyling: false,
    }).then(result => {
      if (result.value) {
        confirmingSummary.value = true
        store.dispatch('calendar/confirmCalendarEventSummary', meetingId.value)
          .then(res => {
            meeting.value.summaryConfirmedAt = res.data.summaryConfirmedAt
            store.dispatch('calendar/fetchCalendarEvent', meetingId.value)
              .then(() => {
                confirmingSummary.value = false
              })
          })
          .catch(err => parseRequestError(err))
          .finally(() => {
            localProcessing.value = false
          })
      }
    })
  }

  const saveCustomerNote = (calendarEventCustomerId, note) => {
    processPathCalendarEventPromise(
      store.dispatch(
        'calendar/patchCalendarEventCustomer',
        {
          calendarEventCustomerId,
          data: { meetingNote: note },
        },
      ),
    )
  }

  const waitingAttendeeNoteTimestamps = {}
  const attendeeNoteChangeHandler = (calendarEventCustomerId, note) => {
    clearTimeout(waitingAttendeeNoteTimestamps[calendarEventCustomerId])
    waitingAttendeeNoteTimestamps[calendarEventCustomerId] = setTimeout(() => {
      saveCustomerNote(calendarEventCustomerId, note)
    }, 2500)
  }

  const updateCustomer = customer => {
    store.dispatch(
      'customer/patchCustomer',
      { customerId: customer.id, data: { notes: customer.notes } },
    )
      .catch(parseRequestError)
  }

  const allEqual = (object, itemId) => Object.values(object).every((val, i, arr) => val[itemId.toString()] === arr[0][itemId.toString()])
  const allChecked = (object, itemId) => Object.values(object).every(val => val[itemId.toString()] === true)

  return {
    processing,
    meeting,
    meetingId,
    presentAttendees,
    script,
    presentStudentsCount,
    summaryConfirmed,

    selected,

    changeHandler,
    allEqual,
    allChecked,

    attendeeChangeHandler,
    attendeeNoteChangeHandler,
    listChangeHandler,
    saveNote,
    confirmSummary,
    transformDatetimeToHumanReadablePretty,
    updateCustomer,
  }
}
