<template>
  <b-card>
    <rc-overlay
      :show="processing"
      no-fade
      variant="white"
      class="h-100"
    >
      <add-settlement-modal
        v-if="!value.paid"
        :type="'incoming_payment'"
        :customer="value.customer"
        :calendar-event-customers="[ value ]"
        @settlement-added="handleNewSettlementAdded"
      />

      <h4 class="card-title">
        <b-link :to="{name: 'organization-customers-view', params: {customerId: value.customer.id}}">
          {{ `${value.customer.lastName} ${value.customer.firstName}` }}
        </b-link>
        <span
          class="presence-icons d-inline-block cursor-pointer"
          @click="togglePresence(value)"
        >
          <feather-icon
            v-if="value.present"
            v-b-tooltip.hover
            :title="$t('meeting.attendees.present')"
            icon="CheckCircleIcon"
            size="16"
            class="text-success"
          />
          <feather-icon
            v-else
            v-b-tooltip.hover
            :title="$t('meeting.attendees.absent')"
            icon="MinusCircleIcon"
            size="16"
            class="text-secondary"
          />
        </span>
      </h4>

      <!-- Contact -->
      <div
        v-if="customerHasContactInfo"
        class="mb-1"
      >
        <h6 class="section-label">
          {{ $t('customers.form.contact-information.title') }}
        </h6>

        <dl
          v-if="value.customer.phone"
          class="row mb-0"
        >
          <dt class="col-md-4">
            {{ $t('customers.label.phone') }}
          </dt>
          <dd class="col-md-8">
            <b-link
              :href="`tel:${value.customer.phone}`"
              target="_blank"
            >
              {{ value.customer.phone }}
            </b-link>
          </dd>
        </dl>

        <dl
          v-if="value.customer.email"
          class="row mb-0"
        >
          <dt class="col-md-4">
            {{ $t('customers.label.email') }}
          </dt>
          <dd class="col-md-8">
            <b-link
              :href="`mailto:${value.customer.email}`"
              target="_blank"
            >
              {{ value.customer.email }}
            </b-link>
          </dd>
        </dl>
      </div>
      <!-- Contact -->

      <!-- Guardian Contact -->
      <div
        v-if="customerHasGuardian"
        class="mb-1"
      >
        <h6 class="section-label">
          {{ $t('customers.form.guardian.title') }}
        </h6>

        <dl
          v-if="value.customer.guardianLastName"
          class="row mb-0"
        >
          <dt class="col-md-4">
            {{ $t('customers.label.lastname') }}
          </dt>
          <dd class="col-md-8">
            {{ value.customer.guardianLastName }}
          </dd>
        </dl>

        <dl
          v-if="value.customer.guardianFirstName"
          class="row mb-0"
        >
          <dt class="col-md-4">
            {{ $t('customers.label.firstname') }}
          </dt>
          <dd class="col-md-8">
            {{ value.customer.guardianFirstName }}
          </dd>
        </dl>

        <dl
          v-if="value.customer.guardianPhone"
          class="row mb-0"
        >
          <dt class="col-md-4">
            {{ $t('customers.label.phone') }}
          </dt>
          <dd class="col-md-8">
            <b-link
              :href="`tel:${value.customer.guardianPhone}`"
              target="_blank"
            >
              {{ value.customer.guardianPhone }}
            </b-link>
          </dd>
        </dl>

        <dl
          v-if="value.customer.guardianEmail"
          class="row mb-0"
        >
          <dt class="col-md-4">
            {{ $t('customers.label.email') }}
          </dt>
          <dd class="col-md-8">
            <b-link
              :href="`mailto:${value.customer.guardianEmail}`"
              target="_blank"
            >
              {{ value.customer.guardianEmail }}
            </b-link>
          </dd>
        </dl>
      </div>
      <!-- Guardian Contact -->

      <!-- Payment -->
      <div
        v-acl="role.advancedEducator"
        class="mb-0"
      >
        <h6 class="section-label">
          {{ $t('meeting.attendees.payment-status') }}
        </h6>

        <dl class="row mb-0">
          <dt class="col-md-4">
            {{ $t('meeting.attendees.amount') }}
          </dt>
          <dd class="col-md-8">
            {{ value.settlement.amount }} zł
          </dd>
        </dl>

        <dl class="row mb-0">
          <dt class="col-md-4">
            {{ $t('meeting.attendees.status') }}
          </dt>
          <dd class="col-md-8">
            <b-badge
              v-if="value.settlement.paid"
              :key="`success-payment-badge-${value.id}`"
              variant="success"
            >
              {{ $t('meeting.attendees.status-paid') }}
            </b-badge>
            <b-badge
              v-else
              :key="`danger-payment-badge-${value.id}`"
              v-b-tooltip.hover
              v-b-modal="`add-settlement-modal-${value.customer.id}`"
              :variant="getColorVariantByPaymentStatus(value.paymentStatus)"
              :title="$t('settlements.btn.add-payment')"
            >
              {{ $t('meeting.attendees.status-not-paid') }}
            </b-badge>
          </dd>
        </dl>

      </div>
      <!-- Contact -->

    </rc-overlay>
  </b-card>
</template>
<script>
import {
  BBadge, BCard, BLink, VBTooltip,
} from 'bootstrap-vue'
import RcOverlay from '@/layouts/components/rc/RcOverlay'
import attendeesCardSetup from '@/views/organization/meeting/tabs/attendees/attendeesCardSetup'
import AddSettlementModal from '@/views/_global/AddSettlementModal'
import role from '@/auth/role'

export default {
  components: {
    BCard,
    BLink,
    BBadge,

    RcOverlay,
    AddSettlementModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    role() {
      return role
    },
    customerHasContactInfo() {
      return this.value.customer.phone || this.value.customer.email
    },
    customerHasGuardian() {
      return this.value.customer.guardianPhone || this.value.customer.guardianEmail
    },
  },
  setup: attendeesCardSetup,
}
</script>
<style lang="scss" scoped>
.presence-icons {
  svg {
    margin-top: -3px;
  }
}
</style>
